import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const PricesPage = () => (
  <Layout>
    <SEO title="Prices" />
    <section class="hero is-primary">
      <div class="hero-body">
        <div class="container">
          <h1 class="title is-size-4">Prices</h1>
        </div>
      </div>
    </section>
    <div class="section" style={{ "padding-top": "1rem" }}>
      <div class="columns">
        <div class="column" />
        <div class="column is-5">
          <table class="table is-hoverable is-fullwidth">
            <thead>
              <tr>
                <th></th>
                <th>Price (€/night)</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Adult</td>
                <td>4,50€</td>
              </tr>
              <tr>
                <td>Child (5 to 12)</td>
                <td>3,50€</td>
              </tr>
              <tr>
                <td>Child (under 5)</td>
                <td>free</td>
              </tr>
              <tr>
                <td>Animals</td>
                <td>1,00€</td>
              </tr>
              <tr>
                <td>Tent (up to 6m / over 6m)</td>
                <td>5,00€ / 6,00€</td>
              </tr>
              <tr>
                <td>Caravan (up to 5m / over 5m)</td>
                <td>5,00€ / 6,00€</td>
              </tr>
              <tr>
                <td>Motorhome (up to 5m / over 5m)</td>
                <td>6,00€ / 7,00€</td>
              </tr>
              <tr>
                <td>Car</td>
                <td>3,50€</td>
              </tr>
              <tr>
                <td>Motorcycle</td>
                <td>2,50€</td>
              </tr>
              <tr>
                <td>Bycicle</td>
                <td>free</td>
              </tr>
              <tr>
                <td>Washing-machine, 1 wash</td>
                <td>5,00€</td>
              </tr>
              <tr>
                <td>Electricity (only available in some plots)</td>
                <td>4,00€</td>
              </tr>
            </tbody>
          </table>
          <div class="content">
            <p>The use of all the equipment (barbecues, tables, refrigerator, freezer, etc.) is free.</p>
            <p></p>
          </div>
        </div>
        <div class="column" />
      </div>
    </div>
  </Layout>
)

export default PricesPage
